import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";
import { parsedUser } from "../../../utils/GetCurrentUser";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import FieldSelector from "../../customComponents/FieldSelector";
import { useTranslation } from "react-i18next";
import PaymentMethods from "./PaymentMethods";
import PaymentDetails from "./PaymentDetails";
import { confirmAlert } from "react-confirm-alert";


const ApplyAdvance = (props) => {
  const user = parsedUser();
  const { t } = useTranslation();
  const [records, setRecords] = useState([]);

  const toast = useToast();
  const [generalError, setGeneralError] = useState(null);
  const [depositBaseCurrency, setDepositBaseCurrency]  = useState(null);
  const [AmountPaidByTypePayments, setAmountPaidByTypePayments] = useState(0);

  const [validationsSchema, setValidationSchema] = useState(null);
  const fields = [
    {
      FieldName: "IdBillingService",
      FieldType: "varchar",
      FieldTitle: "Concept",

      cssClasss: "col-12 ",
      HtmlType: "select",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      DataSource:
        '{"HashID":"115dd1bec9c29a041dfa96e77595387f8141eba2db6f879e8a009838db24b140"}',
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      FieldName: "Details",
      FieldType: "varchar",
      FieldTitle: "Details",

      cssClasss: "col-12 ",
      HtmlType: "textarea",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      //	"DataSource": '{"HashID":"2b868d0982957ef9246670e793308c8cc04ad373d1355554308e6e2e189ab505"}',
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    {
      FieldName: "DetailReferences",
      FieldType: "varchar",
      FieldTitle: "Reference",

      cssClasss: "col-12 ",
      HtmlType: "text",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: "",
      //	"DataSource": '{"HashID":"2b868d0982957ef9246670e793308c8cc04ad373d1355554308e6e2e189ab505"}',
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
    // {
    //   IdAppForm: 64,
    //   Id: 6400005,
    //   FieldName: "IdCurrency",
    //   FieldType: "varchar",
    //   FieldTitle: t("Currency"),
    //   Position: 1,
    //   cssClasss: "col-12",
    //   HtmlType: "select",
    //   PlaceHolder: "",
    //   DefaultValue: "",
    //   OptionValues: null,
    //   DataSource:
    //     '{"HashID":"637f44b9bafa1fe74b0a72c887b1c3262c833080fea07d40768858638f0fc87b"}',
    //   Required: true,
    //   Visible: true,
    //   ReadOnly: false
    // },
    {
      IdAppForm: 64,
      Id: 6400005,
      FieldName: "IdMethodpayment",
      FieldType: "varchar",
      FieldTitle: "Method of payment",
      Position: 1,
      cssClasss: "col-12",
      HtmlType: "select",
      PlaceHolder: "",
      DefaultValue: "",
      OptionValues: null,
      DataSource:
        '{"HashID":"258f835999ba23ffb6602f6c2a5a244751f2aac60bb9d65a577df3d2fc9c693b"}',
      Required: true,
      Visible: true,
      ReadOnly: false
    },
    {
      IdAppForm: 64,
      Id: 6400004,
      FieldName: "Amount",
      FieldType: "number",
      FieldTitle: "Amount",
      Position: 2,
      cssClasss: "col-12 ",
      HtmlType: "number",
      PlaceHolder: "",
      DefaultValue: 0,
      value: 0,
      OptionValues: "",
      DataSource: null,
      Required: true,
      Visible: true,
      ReadOnly: false,
      GroupBy: 0,
    },
  ];
  const bindDataRemote = async () => {};
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const fillFormikObject = (record) => {
    if (fields && fields.length > 0) {
      fields.forEach((item) => {
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const handleSubmit = async (obj, event) => {
    if (AmountPaidByTypePayments < 1) {
      confirmAlert({
        closeOnClickOutside: true,
        message: "The amount paid cannot be 0 or less than 0.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
      return;
    } else if (
      AmountPaidByTypePayments < obj.Amount
        // obj.Amount * depositBaseCurrency.CurrencyRate
    ) {
      confirmAlert({
        closeOnClickOutside: true,
        message: "The amount paid must be greater than the total amount.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
      return;
    }
    console.log("object to save", obj, props.selectedFolio);
    if (obj.Amount <= 0 || !obj.Amount) {
      setGeneralError("Amount must be greater than 0");
      toast({
        type: "error",
        message: "Amount must be greater than 0",
      });
      return;
    }

    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;

      let queryData = {
        Data: `@Details=${
          obj.Details ? "'" + obj.Details + "'" : "null"
        },@DetailReferences=${
          obj.DetailReferences ? "'" + obj.DetailReferences + "'" : "null"
        }, @IdBillingService=${
          obj.IdBillingService ? obj.IdBillingService : "null"
        }, @Amount=${obj.Amount}, @GUIDReservationToFolio='${
          props.selectedFolio.GUIDReservationToFolio
        }', @IdCurrency=${obj.IdCurrency}`,
      };
      // let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.postAction(
        `api/cashier/ApplyAdvance?` + query,
        queryData ?? ""
      );

      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          setGeneralError(response.Msg);
          return;
        }
      }
      toast({
        type: "success",
        message: "Record saved successfully",
      });

      props.toggle();
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    prepareFormikObject();
  }, []);
  useEffect(() => {
    bindDataRemote();
  }, [props.selectedReservation]);
  // console.log(records)
  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      backdrop="static"
      keyboard={false}
      size={props.modalSize ?? "md"}
      // className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>Deposit</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container fluid className="overflow-auto table-responsive">
          <Row>
            <Col xs={12}>
            {fields &&
              fields
                .filter(
                  (x) =>
                    x.FieldTitle !== "Audit Information" &&
                    x.FieldName !== "Id" &&
                    x.FieldName !== "IdCustomer" &&
                    x.FieldName !== "DateAdd" &&
                    x.FieldName !== "File" &&
                    x.FieldName !== "DateMod"
                )
                .map((item, index) => {
                  return (
                    <Col
                      className={
                        item.cssClasss + (!item.Visible ? " d-none" : "")
                      }
                      key={`col-${item.FieldName}-${index}`}
                    >
                      <label className="me-2 mt-2">
                        {item.FieldTitle}
                        {item.Required ? (
                          <i className="required-asterisk ms-1 text-danger">
                            *
                          </i>
                        ) : null}
                      </label>
                      <br />

                      <FieldSelector
                        actionMode={""}
                        model={item}
                        obj={formik.values}
                        key={`field-${item.FieldName}`}
                        updateField={updateField}
                        value={formik.values[item.FieldName]}
                      ></FieldSelector>
                      {formik.errors[item.FieldName] ? (
                        <div className="invalid text-sm my-1">
                          {formik.errors[item.FieldName]}
                        </div>
                      ) : null}
                    </Col>
                  );
                })}
                <hr />
                {/* <PaymentDetails baseCurrency={depositBaseCurrency} AmountPaidByTypePayments={AmountPaidByTypePayments} totalAmountToBePaid={formik.values.Amount}/> */}
                </Col>
                {/* <Col xs={6}>
                <PaymentMethods
                  setAmount={(value) => setAmountPaidByTypePayments(value)}
                  setDepositBaseCurrency={setDepositBaseCurrency}
                />
                </Col> */}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gx-3 align-items-center justify-content-end">
          {Object.values(formik.errors).length > 0 && (
            <span className="invalid me-2">
              Please check the forms for errors
            </span>
          )}
          <button className="btn me-2" type="button" onClick={props.toggle}>
            Close
          </button>
          <button
            className="btn btn-primary me-2"
            type="button"
            onClick={() => [formik.submitForm()]}
          >
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ApplyAdvance;
